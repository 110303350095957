import React, { useState, useEffect } from "react";
import { Row, Container, Breadcrumb, Card, Alert } from "react-bootstrap";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { isUserSignedIn, isUserAdmin } from "../components/accountManagement";
import { SideBarOptions, adminMenuLinks, TopMenuOptions } from "../components/genericMenu";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import Table from "react-bootstrap/Table";
import { store } from "../redux-store";
import { callAPI } from "../components/api";

const uri = "/admin/";

const AdminPage = ({ location }) => {
  const userData = useSelector((state) => state.userData);
  const [bannerMessage, setBannerMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [pendingComments, setPendingComments] = useState(-1);
  const [exeLogsErrorsLastHour, setExeLogsErrorsLastHour] = useState(-1);
  const [exeLogsErrorsForCurrentDate, setExeLogsForCurrentDate] = useState(-1);
  const [exeLogsErrorsForPrevDate, setExeLogsForPrevDate] = useState(-1);
  const [usersNewLastHour, setUsersNewLastHour] = useState(-1);
  const [usersNewForCurrentDate, setUsersNewForCurrentDate] = useState(-1);
  const [usersNewForPrevDate, setUsersNewForPrevDate] = useState(-1);
  const [usersCount, setUsersCount] = useState(-1);
  const [currentDate, setCurrentDate] = useState("")
  const [prevDate, setPrevDate] = useState("")

  console.log("userData.userName=" + userData.userName);

  useEffect(() => {
    const queryMetric = async (metric_name, period) => {
      var result = await callAPI("/metrics/"+metric_name+"/"+period, "GET", null, userData, store, true);
      if (result.status === "success") {
        console.log('api results='+result);
        //setPendingComments(result.data.metric_value);
        return result.data.metric_value;
      } else {
        var error_prefix = "Error getting "+metric_name+" for "+period+": ";
        if (result.apiStatus === 400) {
          setBannerMessage(error_prefix + result.data.detail);
        } else {
          setBannerMessage(error_prefix, result.message);
        }
        console.error(error_prefix, result.message);
        return -1;
      }
    };

    const queryAllMetrics = async() => {
      const current_date = new Date().toISOString().slice(0, 10);
      const prev_date = new Date(new Date().setDate(new Date().getDate()-1)).toISOString().slice(0, 10);
      setCurrentDate(current_date)
      setPrevDate(prev_date);

      setPendingComments(await queryMetric("comments-pending","current"));
      setExeLogsErrorsLastHour(await queryMetric("exe-logs-errors-last-hour","current"));
      setUsersNewLastHour(await queryMetric("users-new-last-hour","current"));
      setUsersCount(await queryMetric("users-count","current")); 
      setExeLogsForCurrentDate(await queryMetric("exe-logs-errors-for-day",current_date)); 
      setExeLogsForPrevDate(await queryMetric("exe-logs-errors-for-day",prev_date)); 
      setUsersNewForCurrentDate(await queryMetric("users-new-for-day",current_date)); 
      setUsersNewForPrevDate(await queryMetric("users-new-for-day",prev_date)); 
      setLoading(false);
    }

    queryAllMetrics();
  },[userData])


  return (
    <Layout pageInfo={{ pageName: "admin" }} sideBarOptions={SideBarOptions(adminMenuLinks, uri, "Admin")}>
      <Seo title="Admin" keywords={[`admin`]} description="Admin page" url={uri} />
      <Container fluid>
        <div id="top-menu">
          <TopMenuOptions menuLinks={adminMenuLinks} currentLink={uri} menuTitle="Admin" />
        </div>
        <Row className="justify-content-left my-3">
          <Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Admin</Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        {isUserSignedIn(userData) && isUserAdmin(userData) ? (
          <Container fluid>
            <Card className="personal-info-card">
              <Card.Body>
                <ModalHeader>
                  <ModalTitle>Admin Dashboard</ModalTitle>
                </ModalHeader>
                <ModalBody>
                  {bannerMessage === "" ? (
                    <></>
                  ) : (
                    <Alert className="ms-2 me-2 mt-2" variant="warning">
                      {bannerMessage}
                    </Alert>
                  )}
                  {loading ? <img width="30" alt="spinner gif" src="/spinner.gif" /> : 
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <td>Metric</td><td>Value</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Pending Comments</td><td>{pendingComments}</td>
                      </tr>
                      <tr>
                        <td>Exe Logs Errors Last Hour</td><td>{exeLogsErrorsLastHour}</td>
                      </tr>
                      <tr>
                        <td>Exe Logs Errors for {prevDate} (UTC)</td><td>{exeLogsErrorsForPrevDate}</td>
                      </tr>
                      <tr>
                        <td>Exe Logs Errors for {currentDate} (UTC)</td><td>{exeLogsErrorsForCurrentDate}</td>
                      </tr>
                      <tr>
                        <td>New Users Last Hour</td><td>{usersNewLastHour}</td>
                      </tr>
                      <tr>
                        <td>New Users for {prevDate} (UTC)</td><td>{usersNewForPrevDate}</td>
                      </tr>
                      <tr>
                        <td>New Users for {currentDate} (UTC)</td><td>{usersNewForCurrentDate}</td>
                      </tr>
                      <tr>
                        <td>User Count</td><td>{usersCount}</td>
                      </tr>
                    </tbody>
                  </Table>
                  }
                </ModalBody>
              </Card.Body>
            </Card>
          </Container>
        ) : (
          <>Not logged in {navigate("/account/signin/")}</>
        )}
      </Container>
    </Layout>
  );
};

export default AdminPage;
