import React from "react";
import { Link } from "gatsby";

export const accountMenuLinks = [
  { menuName: "Personal Info", link: "/account/" },
  { menuName: "Public Profile", link: "/account/profile/" },
  { menuName: "Change Password", link: "/account/change-password/" },
  { menuName: "Saved Recipes", link: "/account/saved-recipes/" },
  { menuName: "My Reviews", link: "/account/reviews/" },
  { menuName: "Sign Out", link: "/account/signout/" },
];

export const adminMenuLinks = [
  { menuName: "Home", link: "/admin/" },
  { menuName: "Comments", link: "/admin/comments/" },
  { menuName: "Execution Logs", link: "/admin/execution-logs/" },
  { menuName: "Recipes", link: "/admin/recipes/" },
  { menuName: "Users", link: "/admin/users/" },
];

export const SideBarOptions = (menuLinks, currentLink, menuTitle) => {
  return (
    <>
      <div id="generic-menu-top">{menuTitle}</div>
      {menuLinks.map((option) => (
        <div key={option.link} className="d-flex generic-menu-links">
          {option.link === currentLink ? (
            <div className="generic-menu-block-current me-1">&nbsp;&nbsp;</div>
          ) : (
            <div className="generic-menu-block me-1">&nbsp;&nbsp;</div>
          )}
          <div>
            <Link className="me-1" to={option.link}>
              {option.menuName}
            </Link>
          </div>
        </div>
      ))}
    </>
  );
};

export const TopMenuOptions = ({ menuLinks, currentLink, menuTitle }) => {
  return (
    <>
      <div id="generic-menu-top" className="mt-1">
        {menuTitle}
      </div>
      {menuLinks.map((option) => (
        <div key={option.link} className="d-flex generic-menu-links">
          {option.link === currentLink ? (
            <div className="generic-menu-block-current me-1">&nbsp;&nbsp;</div>
          ) : (
            <div className="generic-menu-block me-1">&nbsp;&nbsp;</div>
          )}
          <div>
            <Link className="me-1" to={option.link}>
              {option.menuName}
            </Link>
          </div>
        </div>
      ))}
      <div id="top-menu-footer" className="mt-2 mb-3"></div>
    </>
  );
};
